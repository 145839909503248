<template>
  <DateRangePicker
    ref="picker"
    :minDate="minDate"
    v-model="compValue"
    control-container-class=""
    showDropdowns
    :linkedCalendars="false"
    :ranges="compRanges"
    style="width: 280px"
  >
    <template #input="picker">
      <FormTextInput
        name="dateRange"
        :value="`${$date(picker.startDate).format('MMM D, YYYY')} - ${$date(picker.endDate).format(
          'MMM D, YYYY'
        )}`"
        :iconPrefix="faCalendarAlt"
        targetClass="w-full"
        readonly
      ></FormTextInput>
    </template>
  </DateRangePicker>
</template>

<script>
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import FormTextInput from '@/components/forms/elements/FormTextInput'

export default {
  name: 'FormDateRange',
  components: {
    DateRangePicker,
    FormTextInput
  },
  props: {
    value: Object,
    minDate: {
      type: String,
      default: '2015-01-01'
    }
  },
  data() {
    return {
      faCalendarAlt
    }
  },
  computed: {
    compValue: {
      set(newVal) {
        this.$emit('input', newVal)
      },
      get() {
        return this.value
      }
    },

    compRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)

      let yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      return {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0)
        ],
        [`Q1 ${today.getFullYear()}`]: [
          this.$date().month(0).startOf('quarter').toDate(),
          this.$date().month(0).endOf('quarter').toDate()
        ],
        [`Q2 ${today.getFullYear()}`]: [
          this.$date().month(3).startOf('quarter').toDate(),
          this.$date().month(3).endOf('quarter').toDate()
        ],
        [`Q3 ${today.getFullYear()}`]: [
          this.$date().month(6).startOf('quarter').toDate(),
          this.$date().month(6).endOf('quarter').toDate()
        ],
        [`Q4 ${today.getFullYear()}`]: [
          this.$date().month(9).startOf('quarter').toDate(),
          this.$date().month(9).endOf('quarter').toDate()
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-daterange-picker::v-deep .row {
  @apply flex;
}

.vue-daterange-picker::v-deep .col-md-auto {
  @apply flex-auto;
}

.vue-daterange-picker::v-deep .monthselect {
  @apply w-6/12;
}

.vue-daterange-picker::v-deep .yearselect {
  @apply w-5/12 pl-2;
}
</style>
